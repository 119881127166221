import request from '../utils/request';

export const fetchData = query => {
    return request({
        url: './table.json',
        method: 'get',
        params: query
    });
}

export function getUserList({userId,sDate='',eDate='', key = ''}) {    
    return request({url: `/appi/Operate/getUserList?uId=${userId}&sDate=${sDate}&eDate=${eDate}&key=${key}`})
}

export function getSurveyDetail({shtId=''}) {
    console.log('abc')
    return request({
        url: `/appi/Survey/getSurveyDetail?shtId=${shtId}`
    })

}
